.wrap {
  width: 90%;
}

.description {
  margin-top: 1rem;
  margin-bottom: 2rem;
  font-size: 14px;
  padding-right: 2rem;
}
.ratingWrap {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 35px;
}
