.wrap {
  text-align: center;
  width: 96px;
  height: 56px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 8px;
}

.headerWrapper {
  min-height: 24px;
}

.header {
  font-size: 12px;
}
.description {
  font-weight: 300;
  font-size: 12px;
}
.dotWrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 8px;

  width: 64px;
  height: 32px;
}
.dotSolid {
  width: 16px;
  height: 16px;
  border-radius: 1rem;
  background: #8dc642;
  border: 1px solid #8dc642;
}
.dotEmpty {
  width: 16px;
  height: 16px;
  border-radius: 1rem;
  /* background: #8dc642; */
  border: 1px solid #111;
}
