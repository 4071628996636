.wrap {
  position: absolute;
  width: 42px;
  height: 100%;
  right: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 42px;
  background-color: #f7f8f8;
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
}
.icon {
  /* height: 2rem; */
  width: auto;
  fill: #ccc;
  color: #333;
  /* background-color: #333; */
  /* filter: invert(15%) sepia(22%) saturate(7122%) hue-rotate(193deg) brightness(96%) contrast(101%); */
}
.iconActive {
  composes: icon;
  fill: #003a70;
  /* border-left: 3px solid #8dc642; */
}
/* .iconActive:before {
  content: '';

  position: absolute;
  left: 0;
  width: 3px;
  height: 100%;
  background-color: #8dc642;
} */

.navItem {
  height: 30px;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-content: center;
  width: 100%;
}
.navItem:before {
  content: '';
  position: absolute;
  left: 0;
  width: 3px;
  height: 30px;
  background-color: #f7f8f8;
  transition: background-color 0.25s ease-in-out;
}
.navItem:hover {
  background-color: #eee;
  transition: background-color 0.25s ease-in-out;
}

/* .navitem:before {
} */
.navItem:hover:before {
  background-color: #ccc;
}
.navItemActive {
  composes: navItem;
}
.navItemActive:before,
.navItemActive:hover:before {
  content: '';
  position: absolute;
  left: 0;
  width: 3px;
  height: 30px;
  background-color: #8dc642;
}
