.wrap {
  display: flex;
  width: 100%;
  /* height: 300px; */
  /* padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 1rem; */
}
.rightPanel {
  width: 60%;
  padding: 0 1rem;
}
.leftPanel {
  width: 40%;
  position: relative;
  padding-right: 2rem;
}
.leftPanel:after {
  position: absolute;
  content: '';
  right: 1rem;
  top: 10%;
  height: 80%;
  width: 1px;
  border-left: 1px solid #ccc;
}
.header {
  color: #003a70;
  font-size: 18px;
  padding-bottom: 0.5rem;
}
.subHeader {
  color: #111;
  font-size: 18px;
  /* padding-bottom: 1rem; */
}
.scoreWrap {
  padding-top: 2rem;
  text-align: center;
}
.score {
  color: #003a70;
  font-size: 64px;
  font-weight: bold;
  font-family: 'Open Sans', serif;
  margin-top: -1rem;
}

.description {
  font-size: 14px;
  padding-right: 2rem;
}
.list {
  margin: 2rem 0 0 1.5rem;
  list-style: none;
}
.listItem {
  margin-bottom: 1rem;
  position: relative;
  font-size: 13px;
}
.listItem:before {
  position: absolute;
  left: -1.25rem;
  top: -2px;
  content: '';
  display: inline-block;
  transform: rotate(45deg);
  height: 14px;
  width: 6px;
  border-bottom: 4px solid #8ec642;
  border-right: 4px solid #8ec642;
}
