.bodyWrap {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 40px;
  margin-top: 1.5rem;
}
.ratingsWrap {
  width: 90px;
  height: 133px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 1px;
}

.rating {
  text-align: center;
  font-size: 11px;
  padding: 8px 8px 8px 8px;
  min-height: 52px;
  min-width: 82px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ratingEfficient {
  composes: rating;
  border-left: 4px solid #8dc642;
}

.ratingEfficientActive {
  composes: rating;
  border-left: 4px solid #8dc642;
  background-color: rgba(141, 198, 66, 0.2);
}


.ratingAverage {
  composes: rating;
  border-left: 4px solid #97999b;
}

.ratingAverageActive {
  composes: rating;
  border-left: 4px solid #97999b;
  background-color: rgba(208, 194, 194, 1.0);
}

.ratingLessEfficient {
  composes: rating;
  border-left: 4px solid #eb5757;
}

.ratingLessEfficientActive {
  composes: rating;
  border-left: 4px solid #eb5757;
  background-color: rgba(247, 150, 150, 1.0);
}

.ratingDescription {
  font-size: 13px;
  color: #4f4f4f;
  margin-top: 10%;
  padding-right: 2rem;
}
.wrap {
  /* display: flex; */
}
