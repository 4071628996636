.App {
  max-width: 486px;
  display: flex;
  min-height: 250px;
  padding: 1rem 1rem 0 1rem;
  border: 1px solid #ccc;
  border-radius: 1rem;
  position: relative;
  overflow: hidden;
}

/* .App-logo {
  height: 40vmin;
  pointer-events: none;
} */

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */
